<template lang="">
    <div class="wrapper">
        <el-breadcrumb separator="/" class="el-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">资金扶持</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/IndustrialFund' }">产业基金</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="concent">
            <!-- 申报流程 -->
            <section class="section1">
                <div class="clearfix chartView">
                    <div class="bigpic">
                        <img src="../../assets/img/IndustrialFund/qyd_banner2.jpg" alt="">
                    </div>
                    <div class="clearfix top">
                        <div class="title fl">申报流程</div>
                        <el-button class="fr" @click="go('IFList')">
                            项目清单
                        </el-button>
                    </div>
                    <div class="clearfix center">
                        <div class="img-box">
                            <img src="../../assets/img/IndustrialFund/icon_1.png" alt="">
                            <br>
                            <span>
                                1、申报
                            </span>
                        </div>
                        <div class="img-box">
                            <img src="../../assets/img/IndustrialFund/img_jt.png" alt="">
                        </div>
                        <div class="img-box">
                            <img src="../../assets/img/IndustrialFund/icon_2.png" alt="">
                            <br>
                            <span>
                                2、提交申请
                            </span>
                        </div>
                        <div class="img-box">
                            <img src="../../assets/img/IndustrialFund/img_jt.png" alt="">
                        </div>
                        <div class="img-box">
                            <img src="../../assets/img/IndustrialFund/icon_3.png" alt="">
                            <br>
                            <span>
                                3、审核通过
                            </span>
                        </div>
                        <div class="img-box">
                            <img src="../../assets/img/IndustrialFund/img_jt.png" alt="">
                        </div>
                        <div class="img-box">
                            <img src="../../assets/img/IndustrialFund/icon_4.png" alt="">
                            <br>
                            <span>
                                4、签约
                            </span>
                        </div>
                    </div>

                </div>
            </section>
            <!-- 成功案例 -->
            <section class="section2">
                <div class="clearfix chartView">
                    <div class="clearfix top">
                        <div class="title fl">成功案例</div>
                    </div>
                    <div class="clearfix center" style="width: 80%;margin: 36px auto;">
                        <el-carousel :interval="4000" type="card" height="200px" class="tc">
                            <el-carousel-item v-for="item in 5" :key="item">
                                <div class="banner-title">04 / 半导体照明用外延片开发及产业化</div>
                                <div class="banner-concent">
                                    <p class="number">
                                        400万元
                                    </p>
                                    <p style="font-weight: normal;">
                                        基金资助
                                    </p>
                                    <p style="background: #3FC08C;width: 144px;margin: 0 auto;margin-top: 10px;">
                                        西安电子科技大学
                                    </p>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>

                </div>
            </section>
        </div>
    </div>
</template>
<script>
    // 
    export default {
        name: 'demandDetails',
        data() {
            return {

            };
        },
        methods: {
            goback() {
                this.$router.go(-1)
            },
            go(name, id) {
                if (id) {
                    this.$router.push({ name, params: { id } });
                } else {
                    this.$router.push({ name });
                }
            }

        },
        mounted() {

        },
        created() {

        }
    };
</script>
<style lang="scss" scoped>
    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    .tc {
        text-align: center;
    }

    .clearfix::after {
        content: '';
        display: block;
        clear: both;
    }

    .el-button--default {
        background: #135694;
        color: #fff;
    }

    .wrapper {
        padding-left: 83px;
        padding-right: 83px;
        padding-bottom: 15px;
        margin: 0 auto;
        min-width: 1366px;

        section {
            margin-bottom: 16px;
        }

    }

    /deep/ .el-carousel__indicators--outside {
        margin-top: 15px;
        text-align: center;
    }

    /deep/ .el-carousel__item {
        background-color: #fff !important;
        box-shadow: 0 0px 12px 0 rgba(0, 0, 0, .1);
    }
    .bigpic{
        width: 100%;
        margin-bottom: 30px;
    }

    .bigpic img{
        width: 100%;
        height: 300px;;
    }
    .center .banner-title {
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #024A8F;
        font-weight: bold;
        height: 48px;
        line-height: 48px;
    }
    .center .banner-concent {
        font-family: MicrosoftYaHei-Bold;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        background: url(../../assets/img/IndustrialFund/img_bg.png) no-repeat;
        /* background-size: 100% 100%; */
        background-size: cover;
        height: 152px;
    }
    .center .number{
        font-size: 26px;
        padding-top: 24px;
    }
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    

    .section1 .img-box {
        width: 14.28%;
        text-align: center;
        float: left;
        font-size: 16px;
        color: #333333;
    }

    .section1 .img-box img:nth-child(2n-1) {
        width: 86px;
        height: 86px;
        margin-bottom: 10px;
        vertical-align: middle;
    }

    .section1 .img-box:nth-child(2n) img {
        width: 72px;
        height: 12px;
        vertical-align: middle;
    }

    .section1 .img-box:nth-child(2n) {
        line-height: 86px;
    }

    .dataOverview .right-box .list-item {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
    }

    .dataOverview .right-box .list-item:nth-last-of-type(1) {
        margin-bottom: 0;
    }

    .chartView {
        padding: 16px 20px 24px;
        background-color: #fff;
    }
    .section2 .chartView{
        padding-bottom: 0;
    }
    .chartView .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 16px;
    }

    .chartView .item-box {
        width: calc((100% - 40px) * 0.33333);
        height: 176px;
        border-radius: 0px 0px 4px 4px;
        background-image: linear-gradient(180deg, #f6faff 0%, #e9f4ff 100%);
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .chartView .item-box:nth-child(3n + 1) {
        margin-right: 0;
    }

    .chartView .item-box:nth-child(n + 4) {
        margin-bottom: 0;
    }

    .chartView .item-box .item-top {
        border-top: 2px solid #4393f8;
        margin-bottom: 16px;
        position: relative;
    }

    .chartView .item-box .item-top .top-bg {
        height: 0px;
        width: 172px;
        border-top: 40px solid #4393f8;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -96px;
    }


    .chartView .center p {
        line-height: 30px;
    }

    .chartView .item-box .item-bottom {
        height: 40px;
        line-height: 40px;
        padding: 0 25px;
        margin-top: 22px;
        background: #cee4ff;
        border-radius: 0px 0px 4px 4px;
    }

    .chartView .top .time {
        font-size: 14px;
        color: #666666;
        text-align: right;
        line-height: 20px;
        font-weight: 600;
    }

    .chartView .top .img {
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: pointer;
    }

    .chartView .item-box .item-title {
        height: 40px;
        line-height: 40px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        z-index: 1;
        position: relative;
    }

    .concent .title {
        font-family: 'MicrosoftYaHei-Bold';
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        position: relative;
        padding-left: 14px;
    }

    .concent .title:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        left: 0;
    }
</style>